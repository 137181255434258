<template lang='pug'>
.basket-list(v-if="products.length")
  .basket-list__card(v-for="(product, i) in products", :key="i",)
    picture.basket-list__picture
      source(:srcset="`${product.images.w74} 1x, ${product.images.w148} 2x`", type="image/webp", media="(max-width: 1240px)")
      source(:srcset="`${product.images.w160} 1x, ${product.images.w240} 1.5x`", type="image/webp")
      source(:srcset="`${product.images.j160} 1x, ${product.images.j240} 1.5x`")
      img.basket-list__image(:src="product.images.j160", alt="", loading="lazy")
    .basket-list__wrapper
      .basket-list__info
        router-link.basket-list__name.text.link_black-orange(:to="{ name: 'Product', params: { slug: product.slug } }") {{product.name}}
        span.basket-list__color.text-small {{product.color}}
      .basket-list__box
        .basket-list__price-box
          span.basket-list__actual-price.text {{product.price_current.toLocaleString()}} ₽
          .basket-list__old-price-box(v-if="product.price_old > product.price_current")
            span.basket-list__old-price.text-tiny {{product.price_old.toLocaleString()}} ₽
            span.basket-list__discount.text-tiny {{setDiscount(product)}}
        .basket-list__amount
          button.basket-list__amount-button.basket-list__amount-button_minus(type="button", :disabled="product.quantity <= 1", @click="downCount(product)")
          input.basket-list__amount-input(type="number", v-model="product.quantity", @change="changeCount(product)")
          button.basket-list__amount-button.basket-list__amount-button_plus(type="button", @click="upCount(product)")
        button.basket-list__delete.text-tiny.link_black-orange(type="button", @click="deleteProduct(product.id)") Удалить товар
</template>

<script>
import '@/assets/styles/components/basket-list.sass'
import { refreshBasket, removeProduct, setProductCount, refreshProduct } from '../../assets/scripts/Api'

export default {
  name: 'BasketList',
  props: {
    startProducts: Array
  },
  emits: ['change-products'],
  data () {
    return {
      products: []
    }
  },
  methods: {
    setCount (product) {
      this.emitChangeProducts()
      setProductCount(product.id, product.quantity).then(refreshBasket)
    },
    upCount (product) {
      const count = product.quantity = +product.quantity + 1
      this.timeOut(count, product)
    },
    downCount (product) {
      if (product.quantity < 2) return
      const count = product.quantity = +product.quantity - 1
      this.timeOut(count, product)
    },
    changeCount (product) {
      const count = product.quantity = product.quantity > 1 ? product.quantity : 1
      this.timeOut(count, product)
    },
    deleteProduct (id) {
      const currentProduct = this.products.find(product => product.id === id)
      refreshProduct(currentProduct.slug)
      this.products = this.products.filter(product => product.id !== id)
      this.emitChangeProducts()
      removeProduct(id).then(refreshBasket)
    },
    emitChangeProducts () {
      const products = this.products.map(product => (
        { id: product.id, count: product.quantity, price_current: product.price_current, price_old: product.price_old })
      )
      this.$emit('change-products', products)
    },
    timeOut (count, product) {
      setTimeout(() => {
        if (count === product.quantity) {
          this.setCount(product)
          refreshProduct(product.slug)
        }
      }, 300)
    },
    setDiscount (product) {
      return Math.round((product.price_old - product.price_current) * 100 / product.price_old) + '%'
    }
  },
  mounted () {
    this.products = this.startProducts
    this.emitChangeProducts()
  }
}
</script>
