<template lang='pug'>
article.basket(v-if="basket")
  .basket__cont.cont
    h1.basket__caption.caption Корзина
    .basket__body(v-if="basket && basket.products && basket.products.length")
      .basket__area
        basket-list(:start-products="basket.products", @change-products="setProducts")
        basket-form(
            @changeName="setName",
            @changeEmail="setEmail",
            @changePhone="setPhone",
            @send-basket="sendBasket",
            @change-agreement="setAgreement"
          )
      basket-order(:products="products", @send-basket="sendBasket")
    template(v-else)
      p.basket__text-big.text-big
        | В корзине пока ничего нет.
        br
        | Перейдите на главную страницу или воспользуйтесь поиском, если ищете что-то конкретное
      router-link.basket__button-home.button-orange(:to="{ name: 'Home'}") На главную
  tile-list(v-if="productsPopular.length", :products="productsPopular", block-name="Рекомендуем", page='basket')
</template>

<script>
import '@/assets/styles/components/basket.sass'
import TileList from '../_tiles/TileList'
import BasketList from './BasketList'
import BasketOrder from './BasketOrder'
import BasketForm from './BasketForm'
import Storage from '../../assets/scripts/Storage'
import { basketCheckout, getBasket, refreshBasket, getProductsPopularForBasket } from '../../assets/scripts/Api'

export default {
  name: 'TheBasket',
  components: { BasketForm, BasketOrder, BasketList, TileList },
  props: { basketStart: Object },
  data () {
    return {
      basket: null,
      products: [],
      name: null,
      email: null,
      phone: null,
      isAgree: false,
      productsPopular: []
    }
  },
  watch: {
    basketStart (data) {
      this.basket = data
    }
  },
  methods: {
    setProducts (products) {
      this.basket.products = products
      this.products = products
      this.checkBasket()
    },
    setName (name) {
      this.name = name
    },
    setEmail (email) {
      this.email = email
    },
    setPhone (phone) {
      this.phone = phone
    },
    setAgreement (isAgree) {
      this.isAgree = isAgree
    },
    async sendBasket () {
      const res = await basketCheckout(this.products, this.name, this.email, this.phone, this.isAgree)
      if (res.status !== 'success') return this.$router.go(0)
      Storage.showThanks = true
      await this.$router.push({ name: 'Thanks' })
      refreshBasket()
    },
    async getPopularProducts () {
      const data = await getProductsPopularForBasket()
      this.productsPopular = data
    },
    checkBasket () {
      if (!this.basket.products.length) {
        this.getPopularProducts()
      } else {
        this.productsPopular = []
      }
    }
  },
  async mounted () {
    this.basket = await getBasket()
    this.checkBasket()
  }
}
</script>
