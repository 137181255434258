<template lang='pug'>
form.basket-form#basket-form(@submit.prevent="sendBasket")
  h2.basket-form_title.title Оформление заказа
  p.basket-form__text Менеджер свяжется с вами рабочее время, уточнит детали заказа, оплаты и доставки
  .basket-form__input-box
    label.basket-form__label.text(for="user-name") Имя
    input.basket-form__input.input.text(
        v-model="name",
        id="user-name",
        type="text",
        placeholder="Имя",
        @input="changeName"
      )
  .basket-form__input-box
    label.basket-form__label.text(for="user-phone") Телефон*
    input.basket-form__input.input.text(
        v-model="phone",
        id="user-phone",
        type="tel",
        pattern="[0-9-+() ]*",
        placeholder="7 900 000 00 00",
        required,
        @input="changePhone"
    )
  .basket-form__checkbox-box(v-if="!user?.is_auth" )
    input.basket-form__checkbox.text(type="checkbox", id="agreement", name="agreement", v-model="isAgree")
    label.basket-form__checkbox-label(for="agreement") Создать личный кабинет с этими данными
    span.basket-form__checkbox-text.text-tiny Сохраним в нём этот заказ, пароль пришлём на почту
  .basket-form__input-box(v-if="isAgree")
    label.basket-form__label.text(for="user-email") Почта
    input.basket-form__input.basket-form__input_email.input.text(
      v-model="email",
      type="email"
      id="user-email",
      placeholder="mail@mail.ru",
      @input="changeEmail"
    )
  .basket-form__button-box.basket__button-box_form
    button.basket-form__submit.button-orange.text(type="submit") Оформить заказ
    span.basket-form__policy-text.text-tiny и принять условия&thinsp;
      router-link.basket-form__policy-link(:to="{ name: 'Policy'}") Политики конфиденциальности
</template>

<script>
import '@/assets/styles/components/basket-form.sass'
import { getUser } from '@/assets/scripts/Api'
export default {
  name: 'BasketForm',
  emits: ['change-name', 'change-email', 'change-phone', 'send-basket'],
  data () {
    return {
      isAgree: false,
      name: null,
      email: null,
      phone: null,
      user: null
    }
  },
  methods: {
    changeName () {
      this.$emit('change-name', this.name)
    },
    changeEmail () {
      this.$emit('change-email', this.email)
    },
    changePhone () {
      this.$emit('change-phone', this.phone)
    },
    changeAgreement () {
      this.$emit('change-agreement', this.isAgree)
    },
    sendBasket (event) {
      event.submitter.disabled = true
      this.$emit('send-basket')
    },
    cleanForm () {
      this.name = this.user.name
      this.changeName()
      this.email = this.user.email
      this.changeEmail()
      this.phone = this.user.phone
      this.changePhone()
    }
  },
  async mounted () {
    this.user = await getUser()
    if (this.user) this.cleanForm()
  }
}
</script>
