<template lang='pug'>
.basket-order
  h3.basket-order__headline.headline Ваш заказ
  .basket-order__box
    span.basket-order__amount.text {{totalCount}} {{productText}}
    span.basket-order__value.basket__value_price.text {{totalOldPrice.toLocaleString()}} ₽
  .basket-order__box(v-if="totalSale")
    span.basket-order__discount.text Скидка
    span.basket-order__value.basket-order__value_discount.text {{totalSale.toLocaleString()}} ₽
  .basket-order__box.basket-order__box_total
    span.basket-order__total.text-big Итого
    span.basket-order__value.basket-order__value_total.text-big {{totalPrice.toLocaleString()}} ₽
  p.basket-order__text.text-small Стоимость указана без учёта доставки, после оформления заказа менеджер свяжется с вами, чтобы уточнить детали
  .basket-order__button-box.basket-order__button-box_order
    button.basket-order__submit.button-orange.text(form="basket-form") Оформить заказ
    span.basket-order__policy-text.text-tiny и принять условия&thinsp;
      router-link.basket-order__policy-link(:to="{ name: 'Policy'}") Политики конфиденциальности
</template>

<script>
import '@/assets/styles/components/basket-order.sass'
import { getDeclination } from '../../assets/scripts/getDeclination'

export default {
  name: 'BasketOrder',
  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    }
  },
  emits: ['send-basket'],
  computed: {
    totalPrice () {
      let price = 0
      this.products.forEach(product => {
        price += product.price_current * product.count
      })
      return price
    },
    totalCount () {
      let count = 0
      this.products.forEach(product => {
        count += product.count
      })
      return count
    },
    totalSale () {
      return this.totalOldPrice - this.totalPrice
    },
    totalOldPrice () {
      let oldPrice = 0
      this.products.forEach(product => {
        oldPrice += product.price_old * product.count
      })
      return oldPrice
    },
    productText () {
      return getDeclination(this.totalCount, ['товар', 'товара', 'товаров'])
    }
  }
}
</script>
