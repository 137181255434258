export function getDeclination (number, arrayWords) {
  let num = number % 100
  if (num >= 5 && num <= 20) {
    return arrayWords[2]
  } else {
    num = number % 10
    if (num === 1) {
      return arrayWords[0]
    } else if (num >= 2 && num <= 4) {
      return arrayWords[1]
    } else {
      return arrayWords[2]
    }
  }
}
