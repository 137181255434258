<template lang='pug'>
head-meta(title-page="Корзина на сайте CHIEDOHOME.COM", description="Товары в корзине")
the-basket(:basket-start="basket")
</template>

<script>
import TheBasket from '../components/basket/TheBasket'
import { getBasket } from '../assets/scripts/Api'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'BasketPage',
  components: { HeadMeta, TheBasket },
  data () {
    return {
      basket: null
    }
  },
  async beforeRouteEnter (to, from, next) {
    const basket = await getBasket()
    next(vm => { vm.basket = basket })
  }
}
</script>
